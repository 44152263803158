import React, { useState } from 'react';

const AboutPiece = () => {

    return (
        <section className="about-piece-section">
            <div className="auto-container">
                <div className="sec-title text-center">
                    <h3>ABOUT THE PIECE DRM</h3>
                </div>
            
            <div className="row">
                <div className="col-lg-12 col-sm-12">
                    <p className=''>Gimlet is a remarkably ergonomic lounge chair with mid-century styling. The simple shape is actually finely tuned to offer a just-right amount of lumbar and back support, gently correcting your posture for hours of comfort. Available with a matching ottoman in three sophisticated upholstery options — Performance Bouclé in two colors or Top-Grain Leather — it works its magic as either a statement piece or a subtle living room accent.</p>
                    <img src="/images/AboutPiece.png" alt="Blog Post 1" />
                </div>
            </div>
            </div>
        </section>
    );
};
export default AboutPiece;
