import React, { useState } from 'react';

const ProductFilter = () => {

    return (
        <div className="auto-container">
        <div className="row">
            <div className="products-filter">
                <h6>Filter by:</h6>

                <select className="form-select">
                    <option>Material</option>
                    <option>Cotton</option>
                    <option>Leather</option>
                    <option>Silk</option>
                    <option>Wool</option>
                </select>

                <select className="form-select">
                    <option>Colour</option>
                    <option>Red</option>
                    <option>Blue</option>
                    <option>Green</option>
                    <option>Black</option>
                    <option>White</option>
                </select>

                <select className="form-select">
                    <option>Size</option>
                    <option>Small</option>
                    <option>Medium</option>
                    <option>Large</option>
                    <option>Extra Large</option>
                </select>

                <select className="form-select">
                    <option>Price</option>
                    <option>Under ₹5000</option>
                    <option>₹5000 - ₹10000</option>
                    <option>₹10000 - ₹20000</option>
                    <option>Above ₹20000</option>
                </select>

                <select className="form-select">
                    <option>Finish</option>
                    <option>Matte</option>
                    <option>Glossy</option>
                    <option>Satin</option>
                    <option>Textured</option>
                </select>

                <select className="form-select">
                    <option>Advanced Filters</option>
                    <option>Eco-friendly</option>
                    <option>Handmade</option>
                    <option>Limited Edition</option>
                    <option>Customizable</option>
                </select>
            </div>
        </div>
    </div>
    );
};
export default ProductFilter;
