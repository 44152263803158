import React from 'react';

const Stories = () => {
    const storyPosts = [
        {
            name: "Abhay V.",
            date: "September 21, 2024",
            product: "Kai Spherical Table Lamp",
            review: "The marble is top quality and adds a lot of richness. The light cover material looks wonderfully organic. Only downside is that it doesn’t sit completely straight no matter how much you tighten it.",
            imgSrc: "./images/story-1.jpg",
            link: "#",
            rating: 4
        },
        {
            name: "Abhay V.",
            date: "September 21, 2024",
            product: "Bahama Coffee Table",
            review: "Received consignment in very short time and very nicely packed. Packaging quality is really good. Yet to open it completely as coloring work is still going on. Thanks!",
            imgSrc: "./images/story-2.jpg",
            link: "#",
            rating: 5
        },
        {
            name: "Kavita V.",
            date: "September 10, 2024",
            product: "Ala Round Coffee Table",
            review: "It’s a beautiful piece which was so well packed… Every detail is perfect and the table really adds to the room’s elegance.",
            imgSrc: "./images/story-3.jpg",
            link: "#",
            rating: 5
        },
        {
            name: "Sapna S.",
            date: "August 24, 2024",
            product: "Klimt Wall Lamp",
            review: "Stunning piece! The lamp design is exquisite and adds a touch of sophistication to my space.",
            imgSrc: "./images/story-1.jpg",
            link: "#",
            rating: 5
        },
        {
            name: "Prakruthi P.",
            date: "May 16, 2024",
            product: "Klimt Cane Wall Lamp",
            review: "Perfect! Beautifully crafted and the light it gives is soft and warm. Complements my decor perfectly.",
            imgSrc: "./images/story-2.jpg",
            link: "#",
            rating: 5
        },
        {
            name: "Abhay V.",
            date: "September 21, 2024",
            product: "Kai Spherical Table Lamp",
            review: "Really enhances the decor with its premium marble and organic-looking light cover. However, slight alignment issue remains despite tightening.",
            imgSrc: "./images/story-3.jpg",
            link: "#",
            rating: 4
        }
    ];

    return (
        <section className="blog-section p-2">
            <div className="container">
                <div className="text-center">
                    <h2 className="sec-title">Customer Stories</h2>
                </div>
                <div className="row">
                    {storyPosts.map((post, index) => (
                        <div className="col-md-4" key={index}>
                            <div className="blog-block">
                                <div className="inner-box">
                                    <div className="image-box">
                                        <a href={post.link}>
                                            <img src={post.imgSrc} alt="Blog" className="img-fluid" />
                                        </a>
                                    </div>
                                    <div className="content-box">
                                        <ul className="post-meta">
                                            <li className="categories"><a href={post.link}>{post.name}</a></li>
                                            <li className="date">{post.date}</li>
                                        </ul>
                                        {/* <h5 className="product-name">{post.product}</h5>
                                        <div className="rating">
                                            {[...Array(post.rating)].map((_, i) => (
                                                <i className="fa fa-star" key={i}></i>
                                            ))}
                                            {[...Array(5 - post.rating)].map((_, i) => (
                                                <i className="fa fa-star-o" key={i}></i>
                                            ))}
                                        </div> */}
                                        <h6 className="title"><a href={post.link}>{post.review}</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Stories;
