import React, { useState, useEffect } from "react";
import Sidenav from "./modules/Sidenav";
import { Link, useNavigate } from "react-router-dom";
import api from '../../api';
import { toast } from 'react-toastify';

const Wishlists = () => {
  const navigate = useNavigate();
  const [wishlists, setWishlists] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    const userData = sessionStorage.getItem('user_data');
    if (userData) {
        try {
            const parsedData = JSON.parse(userData);
            setLoginData(parsedData);
        } catch {
            setLoginData(null);
        }
    } else {
        setLoginData(null);
    }
  }, []);

  const handleCart = async (id, variant, user_id, quantity) => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      toast.info("You need to be logged in to add items to the cart.");
      return;
    }

    try {
      const response = await api.post('/carts/add', {
        id, variant, user_id, quantity
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data && response.data.result) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message || "An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = sessionStorage.getItem('access_token');

      if (!token) {
        // toast.info("You need to be logged in to view your wishlists.");
        navigate('/login');
        return;
      }

      try {
        const response = await api.get('/wishlists', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setWishlists(response.data.data);
      } catch (err) {
        setError(err.response?.data?.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  const handleWishlistRemove = async (slug) => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      toast.info("You need to be logged in to remove items from the wishlist.");
      return;
    }

    try {
      const response = await api.get(`/wishlists-remove-product/${slug}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.data && response.data.is_in_wishlist === false) {
        toast.success(response.data.message);
      } else {
        toast.error("An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <section className="dashboard">
      <div className="auto-container">
        <div className="d-flex align-items-start">
          <Sidenav />

          <div className="aiz-user-panel">
            <div className="card shadow-none rounded-0 border">
              <div className="card-header border-bottom-0 bg-transparent">
                <h5 className="mb-0 fs-20 fw-700 text-dark">Wishlist</h5>
              </div>
              <div className="card-body">
                {wishlists.length > 0 ? (
                  <div className="row">
                    {wishlists.map((wishlistItem) => (
                      <div className="product-block col-lg-4 col-sm-6" key={wishlistItem.id}>
                        <div className="inner-box">
                          <div className="image">
                            <Link to={`/products/${wishlistItem.product.slug}`}>
                              <img
                                src={wishlistItem.product.thumbnail_image}
                                alt={wishlistItem.product.name}
                              />
                            </Link>
                          </div>
                          <div className="content">
                            <h4>
                              <Link to={`/products/${wishlistItem.product.slug}`}>
                                {wishlistItem.product.name}
                              </Link>
                            </h4>
                            <span className="price">{wishlistItem.product.base_price}</span>
                          </div>
                          <div className="icon-box">
                            <Link className="ui-btn like-btn" to="#" onClick={() => handleWishlistRemove(wishlistItem.product.slug)}>
                              <i className="fa fa-trash"></i>
                            </Link>
                            {loginData ? (
                              <Link to="#" onClick={() => handleCart(wishlistItem.product.id, '', loginData.id, 1)} className="ui-btn add-to-cart">
                                <i className="fa fa-shopping-cart"></i>
                              </Link>
                            ) : (
                              <Link to="/login" className="ui-btn add-to-cart">
                                <i className="fa fa-shopping-cart"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center bg-white p-4">
                    <img
                      className="w-50 h-200px"
                      src="/images/nothing.svg"
                      alt="No Wishlist"
                    />
                    <h5 className="mb-0 h5 mt-3">There isn't anything added yet</h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wishlists;
