import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import api from '../api';

const Footer = (settings) => {
  const [metaData, setMetaData] = useState({});
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const footerData = [
    {
      title: "Explore",
      links: [
        { label: "About Us", path: "#" },
        { label: "Why Us", path: "#" },
        { label: "Measure Your Space", path: "#" },
        { label: "Care And Maintenance", path: "#" },
        { label: "Our Projects", path: "/projects" },
        { label: "Blogs", path: "/blogs" },
        { label: "Contact Us", path: "#" },
      ],
    },
    {
      title: "Services",
      links: [
        { label: "B2B", path: "#" },
        { label: "Design Projects", path: "#" },
        { label: "Franchise", path: "#" },
        { label: "FAQ", path: "#" },
        { label: "Contact", path: "#" },
      ],
    },
    {
      title: "Shop",
      links: [
        { label: "Shop", path: "#" },
        { label: "Rugs", path: "#" },
        { label: "Furniture", path: "#" },
        { label: "Accents", path: "#" },
      ],
    },
    {
      title: "Terms and Conditions",
      links: [
        { label: "Terms and Conditions", path: "/terms-conditions" },
        { label: "Privacy Policy", path: "/privacy-policy" },
        { label: "Return Policy", path: "/return-policy" },
        { label: "Shipping Policy", path: "/shipping-policy" },
        { label: "Warranty Policy", path: "/warranty-policy" },
      ],
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await api.post('/subscriber-add', { email });
      if (response.data && response.data.result) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message || "An error occurred while processing your request.");
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "An error occurred. Please try again.");
    }
  };

  useEffect(() => {
    if (settings.setting && settings.setting.length > 0) {
      const meta = {};
      settings.setting.forEach((setting) => {
        if (setting.type === "footer_logo_path") {
          meta.footer_logo_path = setting.value;
        }
        if (setting.type === "website_name") {
          meta.website_name = setting.value;
        }
        if (setting.type === "frontend_copyright_text") {
          meta.frontend_copyright_text = setting.value;
        }
        if (setting.type === "about_us_description") {
          meta.about_us_description = setting.value;
        }
        if (setting.type === "facebook_link") {
          meta.facebook_link = setting.value;
        }
        if (setting.type === "twitter_link") {
          meta.twitter_link = setting.value;
        }
        if (setting.type === "instagram_link") {
          meta.instagram_link = setting.value;
        }
        if (setting.type === "linkedin_link") {
          meta.linkedin_link = setting.value;
        }
      });
      setMetaData(meta);
    }
  }, [settings]);

  return (
    <footer className="main-footer">
      <div className="widgets-section">
        <div className="auto-container">
          <div className="row align-items-center">
            {/* Newsletter */}
            <div className="footer-column col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
              <div className="footer-widget">
                <div className="newsletter">
                  <p className="widget-title fw-600 text-uppercase">Subscribe to our newsletter</p>
                </div>
              </div>
            </div>
            <div className="footer-column col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
              <div className="footer-widget">
                <div className="newsletter">
                  <form className='d-flex justify-content-center align-items-center' onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      className="theme-btn btn-style-one ms-5"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="row">

            {/* About Us */}
            <div className="footer-column col-lg-4 col-md-6 col-sm-12">
              <div className="footer-widget about-widget text-center">
                <Link to="#">
                  <img className='w-50' src={metaData.footer_logo_path} alt={metaData.website_name} title="" />
                </Link>
                <div className="text" dangerouslySetInnerHTML={{ __html: metaData.about_us_description }}></div>
                <ul className="social-icon-two justify-content-center">
                  <li>
                    <Link to={metaData.twitter_link}>
                      <i className="fab fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.instagram_link}>
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.facebook_link}>
                      <i className="fab fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to={metaData.linkedin_link}>
                      <i className="fab fa-linkedin-in"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Explore */}
            <div className="footer-column d-none d-sm-flex col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget">
                {/* <h5 className="widget-title">About Us</h5> */}
                <ul className="user-links">
                  {/* <li>
                    <Link to="#">About Us</Link>
                  </li>
                  <li>
                    <Link to="#">Why Us</Link>
                  </li>
                  <li>
                    <Link to="#">Measure Your Space</Link>
                  </li>
                  <li>
                    <Link to="#">Care And Maintenance</Link>
                  </li> */}
                  <li>
                    <Link to="/projects">Our Projects</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Contact Us</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            {/* Explore */}
            <div className="footer-column d-none d-sm-flex col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget">
                {/* <h5 className="widget-title">Services</h5> */}
                <ul className="user-links">
                  {/* <li>
                    <Link to="#">B2B</Link>
                  </li>
                  <li>
                    <Link to="#">Design Projects</Link>
                  </li>
                  <li>
                    <Link to="#">Franchise</Link>
                  </li>
                  <li>
                    <Link to="#">FAQ</Link>
                  </li>
                  <li>
                    <Link to="#">Contact</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            {/* Explore */}
            <div className="footer-column d-none d-sm-flex col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget">
                {/* <h5 className="widget-title">Shop</h5> */}
                <ul className="user-links">
                  <li>
                    <Link to="/products">Shop</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Rugs</Link>
                  </li>
                  <li>
                    <Link to="#">Furniture</Link>
                  </li>
                  <li>
                    <Link to="#">Accents</Link>
                  </li> */}
                </ul>
              </div>
            </div>

            {/* Explore */}
            <div className="footer-column d-none d-sm-flex col-lg-2 col-md-6 col-sm-12">
              <div className="footer-widget">
                {/* <h5 className="widget-title">Terms and Conditions</h5> */}
                <ul className="user-links">
                  <li>
                    <Link to="/terms-conditions">Terms and Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/return-policy">Return Policy</Link>
                  </li>
                  <li>
                    <Link to="/shipping-policy">Shipping Policy</Link>
                  </li>
                  <li>
                    <Link to="/warranty-policy">Warranty Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="faq-column mt-4 d-block d-sm-none">
              <div className="inner-column mb-md-50">
                <ul className="accordion-box style-two bg-transparent p-0">
                  {footerData.map((section, index) => (
                    <li
                      className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                      key={index}
                    >
                      <div
                        className={`acc-btn ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleAccordion(index)}
                      >
                        <h5>{section.title}</h5>
                        <div className="icon fa fa-plus"></div>
                      </div>
                      <div
                        className={`acc-content ${activeIndex === index ? 'current' : ''}`}
                        ref={(el) => (contentRefs.current[index] = el)}
                      >
                        <div className="content">
                          <ul className="user-links">
                            {section.links.map((link, linkIndex) => (
                              <li key={linkIndex}>
                                <Link to={link.path}>{link.label}</Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Newsletter */}
            {/* <div className="footer-column col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget">
                <h5 className="widget-title fw-600 text-uppercase">Subscribe to our newsletter</h5>
                <div className="newsletter text-center">
                  <form onSubmit={handleSubmit}>
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    <button
                      type="submit"
                      className="theme-btn btn-style-one bg-light"
                    >
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div> */}

            {/* Contact */}
            {/* <div className="footer-column col-lg-3 col-md-6 col-sm-12">
              <div className="footer-widget contact-widget">
                <h5 className="widget-title">Contact</h5>
                <div className="widget-content">
                  <div className="text">66 Road Broklyn Street, 600 New <br /> York, USA</div>
                  <ul className="contact-info">
                    <li><i className="fa fa-envelope"></i> <Link to="mailto:[email&#160;protected]">[email&#160;protected]</Link></li>
                    <li><i className="fa fa-phone-square"></i> <Link to="tel:+001112223333">+00 111 222 3333</Link></li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div dangerouslySetInnerHTML={{ __html: metaData.frontend_copyright_text }} />
    </footer>
  );
};

export default Footer;
