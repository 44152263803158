import React, { useState, useRef, useEffect } from "react";
import api from "../../../api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddressEdit = ({ userId, addressId, isOpen, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [CountryData, setCountryData] = useState([]);
    const [StateData, setStateData] = useState([]);
    const [CityData, setCityData] = useState([]);
    const [formData, setFormData] = useState({
        user_id: userId,
        address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        phone: ''
    });
    const modalRef = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const token = sessionStorage.getItem('access_token');
        try {
            const response = await api.post('/user/shipping/update', { ...formData, id: addressId }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data.result) {
                toast.success(response.data.message);
                onClose();
            } else {
                toast.error(response.data.message || "An error occurred");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchAddressData = async () => {
            if (!addressId) return;

            setLoading(true);
            const token = sessionStorage.getItem('access_token');
            try {
                const response = await api.get(`/user/shipping/address-with-id/${addressId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                });
                if (response.data.success) {
                    const addressData = response.data.data[0];                    
                    setFormData({
                        user_id: userId,
                        address: addressData.address,
                        country: addressData.country,
                        state: addressData.state,
                        city: addressData.city,
                        postal_code: addressData.postal_code,
                        phone: addressData.phone
                    });
                } else {
                    toast.error("Failed to fetch address.");
                }
            } catch (err) {
                toast.error(err.response?.data?.success || "An error occurred");
            } finally {
                setLoading(false);
            }
        };

        if (isOpen && addressId) {
            fetchAddressData();
        }
    }, [isOpen, addressId, userId]);

    useEffect(() => {
        const fetchCountries = async () => {
            setLoading(true);
            try {
                const { data } = await api.get('/countries');
                if (data.success) {
                    setCountryData(data.data);
                } else {
                    toast.error("Failed to fetch countries.");
                }
            } catch {
                toast.error("An error occurred while fetching countries.");
            } finally {
                setLoading(false);
            }
        };

        fetchCountries();
    }, []);

    useEffect(() => {
        if (formData.country) {
            const fetchStates = async () => {
                setLoading(true);
                try {
                    const { data } = await api.get(`/states-by-country/${formData.country}`);
                    if (data.success) {
                        setStateData(data.data);
                    } else {
                        toast.error("Failed to fetch states.");
                    }
                } catch {
                    toast.error("An error occurred while fetching states.");
                } finally {
                    setLoading(false);
                }
            };

            fetchStates();
        }
    }, [formData.country]);

    useEffect(() => {
        if (formData.state) {
            const fetchCities = async () => {
                setLoading(true);
                try {
                    const { data } = await api.get(`/cities-by-state/${formData.state}`);
                    if (data.success) {
                        setCityData(data.data);
                    } else {
                        toast.error("Failed to fetch cities.");
                    }
                } catch {
                    toast.error("An error occurred while fetching cities.");
                } finally {
                    setLoading(false);
                }
            };

            fetchCities();
        }
    }, [formData.state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        isOpen && (
            <div className="modal fade show" id="new-address-modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                <div className="modal-dialog modal-dialog-centered modal-md" role="document" ref={modalRef}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Address</h5>
                            <button type="button" className="close bg-transparent" onClick={onClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <form className="form-default" onSubmit={handleSubmit}>
                            <div className="modal-body c-scrollbar-light">
                                <div className="p-3">
                                    {/* Address */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>Address</label>
                                        </div>
                                        <div className="col-md-10">
                                            <textarea
                                                className="form-control mb-3 rounded-0"
                                                placeholder="Your Address"
                                                rows="2"
                                                name="address"
                                                required
                                                value={formData.address}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    {/* Country */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>Country</label>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="mb-3">
                                                <select
                                                    className="form-control aiz-selectpicker rounded-0"
                                                    data-live-search="true"
                                                    name="country"
                                                    required
                                                    value={formData.country}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select your country</option>
                                                    {CountryData.map(ctr => (
                                                        <option key={ctr.id} value={ctr.id}>{ctr.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    {/* State */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>State</label>
                                        </div>
                                        <div className="col-md-10">
                                            <select
                                                className="form-control mb-3 aiz-selectpicker rounded-0"
                                                name="state"
                                                required
                                                value={formData.state}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select your state</option>
                                                {StateData.map(state => (
                                                    <option key={state.id} value={state.id}>{state.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* City */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>City</label>
                                        </div>
                                        <div className="col-md-10">
                                            <select
                                                className="form-control mb-3 aiz-selectpicker rounded-0"
                                                name="city"
                                                required
                                                value={formData.city}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select your city</option>
                                                {CityData.map(city => (
                                                    <option key={city.id} value={city.id}>{city.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {/* Postal Code */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>Postal Code</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control mb-3 rounded-0"
                                                placeholder="Your Postal Code"
                                                name="postal_code"
                                                required
                                                value={formData.postal_code}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    {/* Phone */}
                                    <div className="row">
                                        <div className="col-md-2">
                                            <label>Phone</label>
                                        </div>
                                        <div className="col-md-10">
                                            <input
                                                type="text"
                                                className="form-control mb-3 rounded-0"
                                                placeholder="+91"
                                                pattern="[6789][0-9]{9}"
                                                name="phone"
                                                required
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                            />
                                            <small className="form-text text-muted">Format: 1234567890</small>
                                        </div>
                                    </div>
                                    <div className="form-group text-right">
                                        <button type="submit" className="btn btn-primary rounded-0 w-150px" disabled={loading}>
                                            {loading ? "Saving..." : "Save"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddressEdit;
