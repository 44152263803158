import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="auto-container py-5">
      <h2>Terms and Conditions</h2>
      <p>Welcome to Niya Living. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing or using this website, you accept these terms and conditions in full. If you disagree with any part of these terms and conditions, please do not use our website.</p>

      <h4>Use of Our Website</h4>
      <p>By using our website, you warrant that you are at least 18 years old and that you agree to comply with all applicable laws and regulations. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</p>

      <h4>Intellectual Property Rights</h4>
      <p>Unless otherwise stated, Niya Living owns the intellectual property rights for all material on this website. All intellectual property rights are reserved. You may view and/or print pages from this website for your own personal use, subject to the restrictions set in these terms and conditions.</p>
      <ul>
        <li>Republish material from our website.</li>
        <li>Sell, rent, or sub-license material from our website.</li>
        <li>Reproduce, duplicate, or copy material from our website.</li>
        <li>Redistribute content from Niya Living (unless content is specifically made for redistribution).</li>
      </ul>

      <h4>User Content</h4>
      <p>In these terms and conditions, “your content” means material (including text, images, audio, and video) that you submit to our website. You grant Niya Living a non-exclusive, worldwide, irrevocable license to use, reproduce, and distribute your content in any existing or future media.</p>
      <p>Your content must not be illegal, offensive, or infringe on any third-party rights. We reserve the right to remove any content submitted to our website without notice.</p>

      <h4>Limitations of Liability</h4>
      <p>Niya Living will not be liable for any direct, indirect, special, or consequential damages arising out of or in connection with your use of our website. This includes any business losses, loss of revenue, profits, or anticipated savings, or loss of data.</p>

      <h4>Termination</h4>
      <p>We reserve the right to terminate or suspend your access to our website, without prior notice or liability, for any reason whatsoever, including if you breach these terms and conditions.</p>

      <h4>Governing Law</h4>
      <p>These terms and conditions are governed by and construed in accordance with the laws of India, and you submit to the non-exclusive jurisdiction of the courts located in that jurisdiction for the resolution of any disputes.</p>

      <h4>Changes to These Terms</h4>
      <p>We may update these terms and conditions from time to time. Any changes will be posted on this page with a "Last Updated" date. By continuing to use our website after changes are made, you accept the revised terms.</p>

      <h4>Contact Us</h4>
      <p>If you have any questions about these terms and conditions, please contact us at <a href='mailto:contact@niyaliving.in'>contact@niyaliving.in</a>.</p>
    </div>
  );
};

export default TermsAndConditions;