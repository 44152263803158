import React from 'react';
import { Link } from "react-router-dom";

const ClientsSection = () => {
    const clients = [
        { id: 1, src: 'images/clients/1.png', alt: 'Client 1' },
        { id: 2, src: 'images/clients/2.png', alt: 'Client 2' },
        { id: 3, src: 'images/clients/3.png', alt: 'Client 3' },
        { id: 4, src: 'images/clients/4.png', alt: 'Client 4' },
        { id: 5, src: 'images/clients/5.png', alt: 'Client 5' },
        { id: 6, src: 'images/clients/1.png', alt: 'Client 6' },
        { id: 7, src: 'images/clients/2.png', alt: 'Client 7' },
        { id: 8, src: 'images/clients/3.png', alt: 'Client 8' },
        { id: 9, src: 'images/clients/4.png', alt: 'Client 9' },
        { id: 10, src: 'images/clients/5.png', alt: 'Client 10' },
        { id: 11, src: 'images/clients/1.png', alt: 'Client 11' },
        { id: 12, src: 'images/clients/2.png', alt: 'Client 12' },

    ];

    return (
        <section className="clients-section">
            <div className="auto-container">
                <div className="text-center">
                    <h3 className="sec-title">PROUDLY FEATURED IN</h3>
                </div>
                <div className="row">
                    {clients.map(client => (
                        <div className="col-4 col-md-4 col-lg-2 mb-5 text-center" key={client.id}>
                            <Link to="#" className="image">
                                <img src={client.src} alt={client.alt} className="img-fluid" />
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ClientsSection;
