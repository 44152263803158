import React, { useEffect, useState, useRef } from 'react';
import Cookies from 'js-cookie';
import { Link, useNavigate } from "react-router-dom";
import api from '../api';
import { toast } from 'react-toastify';

const UserProfile = () => {
    const [loginData, setLoginData] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const menuRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    const handleLogout = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
    
        const token = sessionStorage.getItem('access_token');
        try {
            const response = await api.get('/auth/logout', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (response.data.result) {
                sessionStorage.removeItem('access_token');
                sessionStorage.removeItem('user_data');
                localStorage.removeItem('access_token');
                Cookies.remove('XSRF-TOKEN');
                setLoginData(null);
                navigate('/login', { state: { message: 'Logged out successfully' } });
            } else {
                toast.error(response.data.message || "An error occurred during logout.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred while logging out.");
        } finally {
            setLoading(false);
        }
    };
         

    const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <div>
            {loginData ? (
                <span
                    className="nav-user-info"
                    id="nav-user-info"
                    onClick={handleMenuToggle}
                    ref={menuRef}
                >
                    <span className="nav-user-img">
                        {loginData.avatar ? (
                            <img
                                src={loginData.avatar}
                                className="img-fit h-100"
                                alt="Avatar"
                            />
                        ) : (
                            <span className="user-initial">{loginData.name.charAt(0).toUpperCase()}</span>
                        )}
                    </span>

                    {/* Click to open menu */}
                    {isMenuOpen && (
                        <div className="user-top-menu">
                            <div className="container">
                                <div className="position-static float-right">
                                    <div className="aiz-user-top-menu">
                                        <ul className="list-unstyled no-scrollbar mb-0 text-left">
                                            <li className="user-top-nav-element border border-top-0">
                                                <Link to="/dashboard" className="text-truncate text-dark px-4 fs-14 d-flex align-items-center hov-column-gap-1">
                                                    <span className="icon lnr lnr-icon-home"></span>
                                                    <span className="user-top-menu-name ms-3">Dashboard</span>
                                                </Link>
                                            </li>
                                            <li className="user-top-nav-element border border-top-0">
                                                <a href="#logout" onClick={handleLogout} className="text-truncate text-dark px-4 fs-14 d-flex align-items-center hov-column-gap-1">
                                                    <span className="icon lnr lnr-icon-power-switch"></span>
                                                    <span className="user-top-menu-name ms-3">Logout</span>
                                                </a>
                                            </li>
                                        </ul>
                                        {loading && <p>Logging out...</p>}
                                        {error && <p className="error">{error}</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </span>
            ) : (
                <li>
                    <Link to="/login" className="ui-btn ui-btn">
                        <span className="icon lnr lnr-icon-user"></span>
                    </Link>
                </li>
            )}
        </div>
    );
};

export default UserProfile;
