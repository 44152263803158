import React from 'react';

const PageTitle = () => {
    return (
        <section className="products-page-title" style={{ backgroundImage: `url(/images/products-page-title.png)` }}>
            <div className="auto-container">
                <div className="row">
                    <div className="col-lg-8">
                        <div className="title-outer">
                            <h1 className="title">Accent Chairs</h1>
                            <span className='text'>
                                Whether you like a little solo seating or need something small to fill an
                                awkward corner, our accent chairs are up for the job. Choose from our
                                range of chair styles and fabric types to match your other Burrow furniture
                                or contrast with your accent piece.</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PageTitle;