import React, { useState, useRef, useEffect } from 'react';
import FooterServices from '../../components/FooterServices';
import AboutPiece from "./modules/AboutPiece";
import KeyFeatures from "./modules/KeyFeatures";
import ProductStatement from "./modules/ProductStatement";
import RelatedProducts from "./modules/RelatedProducts";
import BlogSection from "../Home/modules/BlogSection";
import { Link, useParams } from "react-router-dom";
import api from '../../api';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { toast } from 'react-toastify';

const ProductDetails = () => {
    const { slug } = useParams();
    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [activeIndex, setActiveIndex] = useState(-1);
    const contentRefs = useRef([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [visibleThumbnails, setVisibleThumbnails] = useState([0, 4]);

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const increaseQuantity = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const decreaseQuantity = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
    };

    const handleArrowClick = (direction) => {
        if (product && product.photos) {
            const totalImages = product.photos.length;
            let newIndex = currentIndex;

            if (direction === 'prev') {
                newIndex = currentIndex > 0 ? currentIndex - 1 : totalImages - 1;
            } else if (direction === 'next') {
                newIndex = currentIndex < totalImages - 1 ? currentIndex + 1 : 0;
            }

            setSelectedImage(product.photos[newIndex].path);
            setCurrentIndex(newIndex);

            if (newIndex < visibleThumbnails[0]) {
                setVisibleThumbnails([newIndex, newIndex + 4]);
            } else if (newIndex >= visibleThumbnails[1]) {
                setVisibleThumbnails([newIndex - 3, newIndex + 1]);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get(`/products/${slug}/208`);
                const productData = response.data.data[0];
                setProduct(productData);

                if (productData.photos && productData.photos.length > 0) {
                    setSelectedImage(productData.photos[0].path);
                } else {
                    setSelectedImage(null);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [slug]);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    useEffect(() => {
        contentRefs.current.forEach((content, index) => {
            if (content) {
                content.style.maxHeight = activeIndex === index ? `${content.scrollHeight}px` : '0px';
            }
        });
    }, [activeIndex]);

    const accordionData = product ? [
        { title: 'Description', content: product.description || 'No description available.' },
        { title: 'Maintenance', content: 'Maintenance instructions.' },
        { title: 'Warranty Summary', content: 'Warranty details.' },
        { title: 'Return Policy', content: 'Return policy details.' },
    ] : [];

    const handleCart = async (id, variant, user_id, quantity) => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            toast.info("You need to be logged in to add items to the cart.");
            return;
        }
        try {
            const response = await api.post('/carts/add', {
                id, variant, user_id, quantity
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.data && response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "An error occurred while processing your request.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handleWishlist = async (slug) => {
        const token = sessionStorage.getItem('access_token');

        if (!token) {
            toast.info("You need to be logged in to add items to the wishlist.");
            return;
        }

        try {
            const response = await api.get(`/wishlists-add-product/${slug}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error("An error occurred while processing your request.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    if (!product) return <div>No product data available</div>;

    return (
        <>
            <div className="product-breadcrumb auto-container py-4">
                <ul className="page-breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Living room</li>
                    <li>Accent Chairs</li>
                    <li>{product.name}</li>
                </ul>
            </div>

            <section className="product-details">
                <div className="auto-container pb-70">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6">
                            <div className="main-product-image position-relative">
                                <div className="slider-pager">
                                    {/* Thumbnail list */}
                                    <div className="thumbnail-list">
                                        {product.photos && product.photos.length > 0 ? (
                                            product.photos.slice(visibleThumbnails[0], visibleThumbnails[1]).map((thumbnail, index) => (
                                                <button
                                                    className={`btn thumbnail-btn ${currentIndex === index ? 'active' : ''}`}
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedImage(thumbnail.path); // Change the main image
                                                        setCurrentIndex(index); // Update current index
                                                        setVisibleThumbnails([Math.max(index - 3, 0), Math.min(index + 1, product.photos.length)]); // Update visible thumbnails based on selection
                                                    }}
                                                >
                                                    <img src={thumbnail.path} alt={`Thumbnail ${index + 1}`} />
                                                </button>
                                            ))
                                        ) : (
                                            <div>No thumbnails available</div>
                                        )}
                                    </div>
                                </div>

                                {/* Main image with Lightbox */}
                                {selectedImage ? (
                                    <div className="main-image">
                                        <img
                                            src={selectedImage}
                                            alt="Selected Product"
                                            className="img-fluid"
                                            onClick={() => setIsOpen(true)}
                                        />
                                    </div>
                                ) : (
                                    <div>No image available</div>
                                )}

                                {/* Custom Arrows for navigation */}
                                <div className="nav-arrows">
                                    <button
                                        className="arrow-btn prev"
                                        onClick={() => handleArrowClick('prev')}
                                    >
                                        <i className="fa fa-chevron-up"></i>
                                    </button>
                                    <button
                                        className="arrow-btn next"
                                        onClick={() => handleArrowClick('next')}
                                    >
                                        <i className="fa fa-chevron-down"></i>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-xl-6 product-info">
                            <div className="product-details__top pt-4 pt-md-0">
                                <h3 className="product-details__title">{product.name}</h3>
                            </div>
                            <div className="product-details__review">
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <i className="fa fa-star"></i>
                                <span className="ps-2">(2 Reviews)</span>
                            </div>
                            <div className="prices">{product.main_price}</div>

                            {/* Product Color Selection */}
                            {/* <div className="product-details__color">
                                <h4 className="product-details__color-title">
                                    <span>Color</span> - Moss Green Bouclé
                                </h4>
                                <div className="color-options">
                                    <label className="color-option">
                                        <input type="radio" name="color" value="red" />
                                        <span className="color-swatch" style={{ backgroundColor: '#7E8468' }}></span>
                                    </label>
                                    <label className="color-option">
                                        <input type="radio" name="color" value="blue" />
                                        <span className="color-swatch" style={{ backgroundColor: '#E5E0D8' }}></span>
                                    </label>
                                    <label className="color-option">
                                        <input type="radio" name="color" value="green" />
                                        <span className="color-swatch" style={{ backgroundColor: '#AF8B66' }}></span>
                                    </label>
                                </div>
                            </div> */}

                            <div className="product-details__made">MADE TO ORDER</div>

                            <div className="product-details__buttons">
                                <div className="product-details__quantity">
                                    <div className="quantity-box">
                                        <button type="button" className="sub text-white" onClick={decreaseQuantity}>
                                            <i className="fa fa-minus"></i>
                                        </button>
                                        <input type="number" value={quantity} readOnly />
                                        <button type="button" className="add text-white" onClick={increaseQuantity}>
                                            <i className="fa fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                {loginData !== null ? (
                                    <div className="product-details__buttons-1">
                                        <Link to="#" onClick={() => handleCart(product.id, '', loginData.id, quantity)} className="theme-btn btn-style-one">
                                            <span className="btn-title">Add to Cart</span>
                                        </Link>
                                    </div>
                                ) : (
                                    <div className="product-details__buttons-1">
                                        <Link to="/login" className="theme-btn btn-style-one">
                                            <span className="btn-title">Add to Cart</span>
                                        </Link>
                                    </div>
                                )}

                                <div className="product-details__buttons-2">
                                    <Link to="#" onClick={() => handleWishlist(slug)} className="theme-btn btn-style-one">
                                        <span className="btn-title">Add to Wishlist</span>
                                    </Link>
                                </div>
                            </div>

                            <div className="faq-column">
                                <div className="inner-column mb-md-50">
                                    <ul className="accordion-box style-two bg-transparent p-0">
                                        {accordionData.map((item, index) => (
                                            <li
                                                className={`accordion block ${activeIndex === index ? 'active-block' : ''}`}
                                                key={index}
                                            >
                                                <div className={`acc-btn ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleAccordion(index)}>
                                                    <h5>{item.title}</h5>
                                                    <div className="icon fa fa-plus"></div>
                                                </div>
                                                <div
                                                    className={`acc-content ${activeIndex === index ? 'current' : ''}`}
                                                    ref={(el) => (contentRefs.current[index] = el)}
                                                >
                                                    <div className="content" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <AboutPiece />
            <KeyFeatures />
            <ProductStatement />
            <RelatedProducts />
            <BlogSection />
            <FooterServices />

            {/* Lightbox for image preview */}
            {isOpen && (
                <Lightbox
                    mainSrc={product.photos[currentIndex].path}
                    nextSrc={product.photos[(currentIndex + 1) % product.photos.length].path}
                    prevSrc={product.photos[(currentIndex + product.photos.length - 1) % product.photos.length].path}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => handleArrowClick('prev')}
                    onMoveNextRequest={() => handleArrowClick('next')}
                />
            )}
        </>
    );
};

export default ProductDetails;
