import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import api from '../api';

const CheckoutForm = () => {
    const [loginData, setLoginData] = useState(null);
    const [amount, setAmount] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        apartment: '',
        city: '',
        zip: '',
        country: '',
        orderNotes: '',
        cardName: '',
        cardNumber: '',
        expiryDate: '',
        securityCode: '',
    });

    const initiatePayment = async () => {
        try {
            const response = await api.post('initiate-phonepe-payment', {
                amount: 1
            });

            if (response.data.success) {
                window.location.href = response.data.redirectUrl;
            } else {
                alert("Payment initiation failed: " + response.data.message);
            }
        } catch (error) {
            console.error("Error initiating payment:", error.response || error);
            alert("An error occurred while initiating payment.");
        }
    };

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
                setFormData({
                    name: parsedData.name || "",
                    email: parsedData.email || "",
                    phone: parsedData.phone || "",
                });
            } catch {
                setLoginData(null);
            }
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        // Handle form submission logic here
    };

    return (
        <section>
            <div className="container pt-70">
                <div className="section-content">
                    <form id="checkout-form" onSubmit={handleSubmit}>
                        <div className="row mt-30">
                            <div className="col-md-6">
                                <div className="billing-details">
                                    <h3 className="mb-30">Billing Details</h3>
                                    <div className="row">
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="checkout-form-fname">Name</label>
                                            <input
                                                id="checkout-form-fname"
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Full Name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="checkout-form-lname">Email</label>
                                            <input
                                                id="checkout-form-lname"
                                                type="text"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                readOnly
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="checkout-form-cname">Phone</label>
                                                <input
                                                    id="checkout-form-cname"
                                                    type="text"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Phone"
                                                    value={formData.phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="checkout-form-address">Address</label>
                                                <input
                                                    id="checkout-form-address"
                                                    type="text"
                                                    name="address"
                                                    className="form-control"
                                                    placeholder="Street address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <input
                                                    type="text"
                                                    name="apartment"
                                                    className="form-control"
                                                    placeholder="Apartment, suite, unit etc. (optional)"
                                                    value={formData.apartment}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="checkout-form-city">City</label>
                                            <input
                                                id="checkout-form-city"
                                                type="text"
                                                name="city"
                                                className="form-control"
                                                placeholder="City"
                                                value={formData.city}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label>State/Province</label>
                                            <select
                                                className="form-control"
                                                name="state"
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select State</option>
                                                <option>New South Wales</option>
                                                <option>Victoria</option>
                                                <option>Queensland</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label htmlFor="checkout-form-zip">Zip/Postal Code</label>
                                            <input
                                                id="checkout-form-zip"
                                                type="text"
                                                name="zip"
                                                className="form-control"
                                                placeholder="Zip/Postal Code"
                                                value={formData.zip}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                        <div className="mb-3 col-md-6">
                                            <label>Country</label>
                                            <select
                                                className="form-control"
                                                name="country"
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">Select Country</option>
                                                <option>Australia</option>
                                                <option>UK</option>
                                                <option>USA</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <h3>Additional Information</h3>
                                <label htmlFor="order_comments">
                                    Order notes&nbsp;<span className="optional">(optional)</span>
                                </label>
                                <textarea
                                    id="order_comments"
                                    className="form-control"
                                    placeholder="Notes about your order, e.g. special notes for delivery."
                                    rows="3"
                                    name="orderNotes"
                                    value={formData.orderNotes}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            <div className="col-md-12 mt-30">
                                <h3>Your Order</h3>
                                <table className="table table-striped table-bordered tbl-shopping-cart">
                                    <thead>
                                        <tr>
                                            <th>Photo</th>
                                            <th>Product Name</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <Link to="shop-product-details.html">
                                                    <img alt="product" src="images/resource/products/1.jpg" />
                                                </Link>
                                            </td>
                                            <td className="product-name">
                                                <Link to="shop-product-details.html">Headphone</Link> x 2
                                            </td>
                                            <td>
                                                <span className="amount">₹36.00</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <Link to="shop-product-details.html">
                                                    <img alt="product" src="images/resource/products/2.jpg" />
                                                </Link>
                                            </td>
                                            <td className="product-name">
                                                <Link to="shop-product-details.html">Luggage</Link> x 3
                                            </td>
                                            <td>
                                                <span className="amount">₹115.00</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="product-thumbnail">
                                                <Link to="shop-product-details.html">
                                                    <img alt="product" src="images/resource/products/3.jpg" />
                                                </Link>
                                            </td>
                                            <td className="product-name">
                                                <Link to="shop-product-details.html">Watch</Link> x 1
                                            </td>
                                            <td>
                                                <span className="amount">₹68.00</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cart Subtotal</td>
                                            <td>&nbsp;</td>
                                            <td>₹180.00</td>
                                        </tr>
                                        <tr>
                                            <td>Shipping and Handling</td>
                                            <td>&nbsp;</td>
                                            <td>Free Shipping</td>
                                        </tr>
                                        <tr>
                                            <td>Order Total</td>
                                            <td>&nbsp;</td>
                                            <td>₹250.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-12 mt-60">
                                <div className="payment-method">
                                    <h3>Choose a Payment Method</h3>
                                    <ul className="accordion-box">
                                        <li className="accordion block active-block">
                                            <div className="acc-btn active">
                                                <div className="icon-outer">
                                                    <i className="lnr-icon-chevron-down"></i>
                                                </div>
                                                Credit Card / Debit Card
                                            </div>
                                            <div className="acc-content current">
                                                <div className="payment-info">
                                                    <div className="row clearfix">
                                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                            <div className="field-input mb-3">
                                                                <input
                                                                    type="text"
                                                                    name="cardName"
                                                                    className="form-control"
                                                                    placeholder="Name on the Card"
                                                                    value={formData.cardName}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                            <div className="field-input mb-3">
                                                                <input
                                                                    type="text"
                                                                    name="cardNumber"
                                                                    className="form-control"
                                                                    placeholder="Card Number"
                                                                    value={formData.cardNumber}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                            <div className="field-input mb-3">
                                                                <input
                                                                    type="text"
                                                                    name="expiryDate"
                                                                    className="form-control"
                                                                    placeholder="Expiry Date (MM/YY)"
                                                                    value={formData.expiryDate}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12 column">
                                                            <div className="field-input mb-3">
                                                                <input
                                                                    type="text"
                                                                    name="securityCode"
                                                                    className="form-control"
                                                                    placeholder="Security Code"
                                                                    value={formData.securityCode}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <div className="mt-3">
                                        <button type="button" className="theme-btn btn-style-one">
                                            <span className="btn-title" onClick={initiatePayment} >Place Order</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default CheckoutForm;

