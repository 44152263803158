import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../api";
import Cookies from 'js-cookie';

const Login = () => {
    const [loginData, setLoginData] = useState(null);
    const [formData, setFormData] = useState({
        login_by: "email",
        email: "",
        password: "",
        // remember: false,
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);
    useEffect(() => {
        if (loginData) {
            const redirectPath = localStorage.getItem('redirectPath') || '/';
            localStorage.removeItem('redirectPath');
            navigate(redirectPath);
        }
    }, [loginData, navigate]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRadioChange = (e) => {
        setFormData({
            ...formData,
            login_by: e.target.value,
            email: ""
        });
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
    
        try {
            const response = await api.post('/auth/login', formData);
            if (response.data.result) {
                sessionStorage.setItem('access_token', response.data.access_token);
                sessionStorage.setItem('user_data', JSON.stringify(response.data.user));
    
                //localStorage.setItem('access_token', response.data.access_token);
                // Cookies.set('user_data', JSON.stringify(response.data.user), { expires: 7 });
    
                const redirectPath = localStorage.getItem('redirectPath') || '/';
                localStorage.removeItem('redirectPath');
    
                navigate(redirectPath, { state: { message: 'Logged in successfully' } });
                window.location.reload();
            } else {
                setError(response.data.message || "An error occurred");
            }
        } catch (err) {
            setError(err.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <section className="login-form">
            <div className="auto-container">
                <div className="row">
                    <div className="col-lg-10 col-md-8 mx-auto py-lg-4">
                        <div className="card shadow-none rounded-0 border-0">
                            <div className="row">
                                <div className="col-lg-6">
                                    <img
                                        src="images/collection.png"
                                        alt="Customer Login Page"
                                        className="img-fit h-100"
                                    />
                                </div>

                                <div className="col-lg-6 p-4 p-lg-4 d-flex flex-column justify-content-center border">
                                    <div className="logo mb-3 mx-auto mx-lg-0">
                                        <img
                                            src="images/favicon.png"
                                            alt="Site Icon"
                                            className="img-fit"
                                        />
                                    </div>

                                    <div className="text-left">
                                        <h2 className="heading">Welcome Back!</h2>
                                        <h5 className="sub-heading">Login to your account.</h5>
                                    </div>

                                    <div className="pt-3">
                                        <form className="form-default" onSubmit={handleSubmit}>
                                            <div className="form-group d-none">
                                                <label htmlFor="login_by" className="form-label">Login By</label>
                                                <div>
                                                    <label className="radio-inline me-3">
                                                        <input
                                                            type="radio"
                                                            name="login_by"
                                                            value="email"
                                                            checked={formData.login_by === "email"}
                                                            onChange={handleRadioChange}
                                                        /> Email
                                                    </label>
                                                    <label className="radio-inline">
                                                        <input
                                                            type="radio"
                                                            name="login_by"
                                                            value="phone"
                                                            checked={formData.login_by === "phone"}
                                                            onChange={handleRadioChange}
                                                        /> Phone
                                                    </label>
                                                </div>
                                            </div>

                                            
                                                <div className="form-group">
                                                    <label htmlFor="email" className="form-label">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-0"
                                                        placeholder="johndoe@example.com"
                                                        name="email"
                                                        id="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                </div>
                                            

                                            <div className="form-group">
                                                <label htmlFor="password" className="form-label">
                                                    Password
                                                </label>
                                                <div className="position-relative">
                                                    <input
                                                        type={showPassword ? "text" : "password"}
                                                        className="form-control rounded-0"
                                                        placeholder="Password"
                                                        name="password"
                                                        id="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                        required
                                                    />
                                                    <i
                                                        className={`password-toggle icon far ${
                                                            showPassword ? "fa-eye-slash" : "fa-eye"
                                                        }`}
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    ></i>
                                                </div>
                                            </div>

                                            <div className="row mb-2">
                                                {/* <div className="col-6">
                                                    <label className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="remember"
                                                            checked={formData.remember}
                                                            onChange={handleCheckboxChange}
                                                        />
                                                        <span className="checkbox-text ms-2">
                                                            Remember Me
                                                        </span>
                                                    </label>
                                                </div> */}
                                                <div className="col-6 text-right">
                                                    <a href="/password/reset" className="text-reset">
                                                        <u>Forgot password?</u>
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="mb-4 mt-2">
                                                <button
                                                    type="submit"
                                                    className="theme-btn btn-style-one rounded-0"
                                                    disabled={loading}
                                                >
                                                    {loading ? "Logging in..." : "Login"}
                                                </button>
                                            </div>

                                            {error && <p className="text-danger">{error}</p>}
                                        </form>

                                        <div className="text-center mb-3">
                                            <span className="text-gray">Or Login With</span>
                                        </div>
                                        <ul className="social text-center mb-4">
                                            <li className="list-inline-item">
                                                <a href="# " className="google">
                                                    <i className="fa-brands fa-google"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="# " className="facebook">
                                                    <i className="fa-brands fa-facebook-f"></i>
                                                </a>
                                            </li>
                                        </ul>

                                        <p className="fs-12 text-gray mb-0">
                                            Don't have an account?
                                            <a
                                                href="/registration"
                                                className="ms-2 fw-700 animate-underline-primary"
                                            >
                                                Register Now
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
