import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductBanner = () => {
    const [loading, setLoading] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const loadData = () => {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        };

        loadData();
    }, []);

    const tooltipPositions = isMobile
        ? {
            MC1: { top: '250px', left: '20px' },
            MC2: { top: '150px', left: '150px' },
            MC3: { top: '300px', left: '250px' },
            MC4: { top: '300px', left: '30px' }
        }
        : {
            MC1: { top: '430px', left: '85px' },
            MC2: { top: '180px', left: '300px' },
            MC3: { top: '405px', left: '520px' },
            MC4: { top: '405px', left: '885px' }
        };

    return (
        <div className="tooltip-section">
            <img
                src="/images/banner.png"
                alt="Product Banner"
                className="floorplan-img img-responsive"
            />
            <div className="tooltip-contant">
                <h4 className="title">
                    BE FIRST TO EXPLORE OUR NEW ARRIVALS
                </h4>
                <Link to="#" className="theme-btn btn-style-three">
                    <span className="btn-title">EXPLORE NOW</span>
                </Link>
            </div>

            {loading ? (
                <>
                    <Skeleton height={30} width={100} style={tooltipPositions.MC1} />
                    <Skeleton height={30} width={100} style={tooltipPositions.MC2} />
                    <Skeleton height={30} width={100} style={tooltipPositions.MC3} />
                    <Skeleton height={30} width={100} style={tooltipPositions.MC4} />
                </>
            ) : (
                <>
                    <Tooltip id="MC1" position={tooltipPositions.MC1} building="BUCKET" link="#" prices="200" />
                    <Tooltip id="MC2" position={tooltipPositions.MC2} building="RANK" link="#" prices="300" />
                    <Tooltip id="MC3" position={tooltipPositions.MC3} building="DINING CHAIR" link="#" prices="400" />
                    <Tooltip id="MC4" position={tooltipPositions.MC4} building="DINING TABLE" link="#" prices="500" />
                </>
            )}
        </div>
    );
}

const Tooltip = ({ id, position, building, link, prices }) => {
    const [hovered, setHovered] = useState(false);

    return (
        <div 
            className="map-tooltip d-none d-md-flex" 
            id={id} 
            style={{ top: position.top, left: position.left }} 
            onMouseEnter={() => setHovered(true)} 
            onMouseLeave={() => setHovered(false)}
        >
            <h5 className='tooltip-text'>{building}</h5>
            <div className={`map-tooltip-info ${hovered ? 'visible' : ''}`}>
                <Link to={link}>
                    <h5>{building}</h5>
                    <p>
                        <span>₹ {prices}</span>
                    </p>
                </Link>
                <div className="arrow"></div>
            </div>
            <div className="line"></div>
        </div>
    );
}

export default ProductBanner;
