import React, { useState } from 'react';

const KeyFeatures = () => {

    return (
        <section className="key-features-section">
            <div className="auto-container">
                <div className="sec-title text-center">
                    <h3>KEY FEATURES</h3>
                </div>

                <div className="row">
                    <div className="key-features col-lg-3 col-sm-12">
                        <img src="/images/KeyFeatures-1.png" alt="KeyFeatures" />
                        <h5>Ergonomic design</h5>
                        <p>The subtle curve offers just the right amount of lumbar support for hours of comfort.</p>
                    </div>

                    <div className="key-features col-lg-3 col-sm-12">
                        <img src="/images/KeyFeatures-2.png" alt="KeyFeatures" />
                        <h5>Matching ottoman</h5>
                        <p>Kick your feet up with an optional matching upholstered ottoman.</p>
                    </div>

                    <div className="key-features col-lg-3 col-sm-12">
                        <img src="/images/KeyFeatures-3.png" alt="KeyFeatures" />
                        <h5>Premium upholstery</h5>
                        <p>Available in Top-Grain Leather imported from Italy, or stylish Performance Bouclé fabric.</p>
                    </div>

                    <div className="key-features col-lg-3 col-sm-12">
                        <img src="/images/KeyFeatures-4.png" alt="KeyFeatures" />
                        <h5>Simple assembly</h5>
                        <p>Take it out of the box, attach the seat and the back, and you’re set up in moments.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default KeyFeatures;
