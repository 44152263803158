import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import api from '../../api';

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await api.get('/projects');
            setProjects(response.data.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    fetchData();
}, []);

  return (
    <>
      <section className="page-title" style={{ backgroundImage: 'url(/images/background/17.jpg)' }}>
        <div className="auto-container">
          <div className="title-outer text-center">
            <h1 className="title">Projects</h1>
            <ul className="page-breadcrumb">
              <li><Link to="/">Home</Link></li>
              <li>Projects</li>
            </ul>
          </div>
        </div>
      </section>


      <section className="project-section">
        <div className="auto-container">
          <div className="row">
            {projects.map((project, index) => (
              <div className="col-md-4" key={index}>
                <div className="project-block">
                  <div className="inner-box">
                    <div className="image-box">
                      <figure className="image">
                        <Link to={`/project/${project.slug}`}>
                          <img src={project.banner} alt={project.title} />
                        </Link>
                      </figure>
                    </div>
                    <div className="content-box">
                      <Link to={`/project/${project.slug}`} className="theme-btn read-more">
                        <i className="fa far fa-arrow-up"></i>
                      </Link>
                      <br />
                      <h4 className="title">
                        <Link to={`/project/${project.slug}`}>{project.title}</Link>
                      </h4>
                      <span className="cat">{project.client}</span>
                    </div>
                    <div className="overlay-1"></div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
