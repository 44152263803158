import React, { useState, useEffect, useRef } from 'react';
import api from '../api';

const CartBox = () => {
    const [isCartVisible, setIsCartVisible] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [cartItems, setCartItems] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [GrandTotal, setGrandTotal] = useState(null);
    const cartRef = useRef(null);

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (loginData && loginData.id) {
                const token = sessionStorage.getItem('access_token');
                const formData = new FormData();
                formData.append('user_id', loginData.id);

                const queryString = new URLSearchParams(formData).toString();

                try {
                    const response = await api.post(`/carts?${queryString}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });
                    setGrandTotal(response.data.grand_total);
                    setCartItems(response.data.data[0]['cart_items']);
                } catch (err) {
                    setError(err);
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [loginData]);
    const toggleCartVisibility = () => {
        setIsCartVisible(!isCartVisible);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cartRef.current && !cartRef.current.contains(event.target)) {
                setIsCartVisible(false);
            }
        };

        if (isCartVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isCartVisible]);

    const removeItemFromCart = async (id) => {
        try {
            const token = sessionStorage.getItem('access_token');

            const response = await api.delete(`/carts/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.result) {
                setCartItems(cartItems.filter(item => item.id !== id));
            } else {
                setError('Failed to remove item from cart');
            }

        } catch (error) {
            console.error('Failed to remove item from cart:', error);
            setError('Failed to remove item from cart');
        }
    };

    return (
        <div className="cart-box" ref={cartRef}>
            <button className="ui-btn ui-btn cart-btn" onClick={toggleCartVisibility}>
                <i className="icon lnr lnr-icon-cart"></i>
                <span className="count">{cartItems.length}</span>
            </button>

            {isCartVisible && (
                <div className="dropdown-menu dropdown-menu-right dropdown-menu-lg p-0 rounded-0 show">
                    <div className="fw-700 pt-4 pb-2 mx-4 border-bottom">
                        Cart Items
                    </div>

                    {cartItems.length === 0 ? (
                        <div className="text-center p-3">
                            <i className="icon lnr lnr-icon-cart1 opacity-60 mb-3"></i>
                            <h5 className="fw-700">Your Cart is empty</h5>
                        </div>
                    ) : (
                        <>
                            <ul className="h-360px overflow-auto c-scrollbar-light list-group list-group-flush mx-1">
                                {cartItems.map(item => (
                                    <li key={item.id} className="list-group-item border-0 hov-scale-img">
                                        <span className="d-flex align-items-center">
                                            <a href="#" className="text-reset d-flex align-items-center flex-grow-1">
                                                <img src={item.product_thumbnail_image} alt={item.product_name} className="img-fit size-60px has-transition" />
                                                <span className="ps-2 flex-grow-1">
                                                    <span className="fw-700 text-dark text-truncate-2">
                                                        {item.product_name}
                                                    </span>
                                                    <span className="fw-400 text-secondary">{item.quantity} x</span>
                                                    <span className="fw-400 text-secondary"> {item.product_price}</span>
                                                </span>
                                            </a>
                                            <span>
                                                <button onClick={(e) => { e.stopPropagation(); removeItemFromCart(item.id); }} className="btn btn-sm btn-icon">
                                                    <i className="icon lnr lnr-icon-close fw-600 text-secondary"></i>
                                                </button>
                                            </span>
                                        </span>
                                    </li>
                                ))}
                            </ul>

                            <div className="px-3 py-2 border-top d-flex justify-content-between mx-4">
                                <span className="fw-400 text-secondary">Subtotal</span>
                                <span className="fw-700 text-dark">{GrandTotal}</span>
                            </div>

                            <div className="py-3 text-center border-top mx-4">
                                <a href="/cart" className="theme-btn btn-style-one">
                                    View cart
                                </a>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CartBox;
