import React from 'react';

const ServicesSection = () => {
    return (
        <section className="service-section">
            <div className="auto-container border-top">
                <div className="row pt-5">

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-3.png" alt="service" />
                        <h6 className="title">
                            Solid Wood Furniture
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-4.png" alt="service" />
                        <h6 className="title">
                            Green Energy
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-7.png" alt="service" />
                        <h6 className="title">
                            Contemporary Design
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-8.png" alt="service" />
                        <h6 className="title">
                            Designed To Last
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-1.png" alt="service" />
                        <h6 className="title">
                            Free Shipping
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-2.png" alt="service" />
                        <h6 className="title">
                            Safe Home Delivery
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-5.png" alt="service" />
                        <h6 className="title">
                            12 Months Warranty
                        </h6>
                    </div>

                    <div className="service-block col-lg-3 col-md-6 col-sm-6 col-3 wow fadeInUp">
                        <img src="/images/icons/icon-6.png" alt="service" />
                        <h6 className="title">
                            EMI Available
                        </h6>
                    </div>

                </div>
            </div>

        </section>
    );
};

export default ServicesSection;
