import React, { useEffect, useState } from 'react';
import Preloader from './components/Preloader';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import ScrollButton from './components/ScrollButton';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import Login from './components/Login';
import Registration from './components/Registration';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import ProductDetails from "./pages/Products/ProductDetails";
import ProductCategories from "./pages/Products/ProductCategories";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/Blogs/BlogDetails";
import Projects from "./pages/Projects/Projects";
import Dashboard from "./pages/Dashboard/Dashboard";
import PurchaseHistory from "./pages/Dashboard/PurchaseHistory";
import Wishlists from "./pages/Dashboard/Wishlists";
import Profile from "./pages/Dashboard/Profile";
import ProjectDetails from "./pages/Projects/ProjectDetails";
import WarrantyPolicy from "./pages/Policy/WarrantyPolicy";
import ShippingPolicy from "./pages/Policy/ShippingPolicy";
import ReturnPolicy from "./pages/Policy/ReturnPolicy";
import PrivacyPolicy from "./pages/Policy/PrivacyPolicy";
import TermsConditions from "./pages/Policy/TermsConditions";
import Stories from "./pages/Stories/Stories";
import api from './api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import PaymentResponse from './components/PaymentResponse ';

function App() {
  const [settings, setSettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    const userData = sessionStorage.getItem('user_data');
    if (userData) {
      try {
        const parsedData = JSON.parse(userData);
        setLoginData(parsedData);
      } catch {
        setLoginData(null);
      }
    } else {
      setLoginData(null);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get('/business-settings');
        setSettings(response.data.data.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Router>
      <ToastContainer position="bottom-left" />
      <ScrollToTop />
      <div className="App">
        <Preloader />
        <Header />
        <LocationToast />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:slug" element={<ProductDetails />} />
          <Route path="/category/:slug" element={<ProductCategories />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<BlogDetails />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/project/:slug" element={<ProjectDetails />} />
          <Route path="/stories" element={<Stories />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment-response" element={<PaymentResponse/>} />
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route 
            path="/dashboard" 
            element={loginData ? <Dashboard /> : <Navigate to="/login" replace />} 
          />
          <Route path="/purchase-history" element={<PurchaseHistory />} />
          <Route path="/wishlists" element={<Wishlists />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/warranty-policy" element={<WarrantyPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/return-policy" element={<ReturnPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer setting={settings} />
      </div>
      <ScrollButton />
    </Router>
  );
}

function LocationToast() {
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      toast.success(location.state.message);
    }

    if (location.state?.error) {
      toast.error(location.state.error);
    }
  }, [location.state]);

  return null;
}

export default App;
