import React, { useState } from 'react';

const ProductStatement = () => {

    return (
        <section className="product-statement-section">
            <div className="auto-container">

                <div className="row">

                    <div className="col-lg-6 col-sm-12">
                        <img src="/images/ProductStatement.png" alt="Blog Post 1" />
                    </div>

                    <div className="col-lg-6 col-sm-12">
                        <h3 className="sec-title">Makes a simple, subtle statement</h3>
                        <p className="text">Though you may associate comfort with those big, chunky, overstuffed chairs that may or may not have a lever on the side, Gimlet is every bit as comfortable, even though the lithe profile might make you wonder how. It has a gently curved seat and back, which is designed to guide your posture into a comfortable, supported position — providing sustainable comfort for hours.</p>

                    </div>
                </div>
            </div>
        </section>
    );
};
export default ProductStatement;
