import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import api from '../api';

const ShoppingCart = () => {
    const [quantities, setQuantities] = useState([]);
    const [loginData, setLoginData] = useState(null);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (loginData && loginData.id) {
                const token = sessionStorage.getItem('access_token');
                const formData = new FormData();
                formData.append('user_id', loginData.id);

                const queryString = new URLSearchParams(formData).toString();

                try {
                    const response = await api.post(`/carts?${queryString}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    });

                    const cartData = response.data.data[0];

                    if (cartData && cartData['cart_items'] && cartData['cart_items'].length > 0) {
                        setProducts(cartData['cart_items']);
                        setQuantities(cartData['cart_items'].map(item => item.quantity || 1));
                    } else {
                        setProducts([]);
                        setQuantities([]);
                    }
                } catch (err) {
                    setError(err.message || 'Failed to load cart data');
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [loginData]);

    const handleQuantityChange = (index, increment) => {
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = Math.max(1, newQuantities[index] + increment);
            return newQuantities;
        });
    };

    const removeItemFromCart = async (id) => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await api.delete(`/carts/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.data.result) {
                setProducts(products.filter(product => product.id !== id));
                setQuantities(quantities.filter((_, index) => products[index].id !== id));
            } else {
                setError('Failed to remove item from cart');
            }
        } catch (error) {
            setError('Failed to remove item from cart');
        }
    };

    const extractNumbers = (data) => {
        return parseFloat(data.replace(/[₹,]/g, '').trim()) || 0;
    };

    const calculateCartTotal = () => {
        return quantities.reduce((sum, qty, index) => {
            return sum + (qty * products[index].product_price);
        }, 0);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section>
            <div className="container pt-0">
                <div className="section-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive text-center">
                                {products.length === 0 ? (
                                    <section className="error-page">
                                        <div className="auto-container">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="error-page__inner">
                                                        <div className="error-page__title-box">
                                                            <img src="/images/empty-shopping-cart.avif" alt="empty shopping cart" />
                                                            <h3 className="error-page__sub-title">Your cart is currently empty!</h3>
                                                        </div>
                                                        <Link to="/products" className="theme-btn btn-style-one shop-now">
                                                            <span className="btn-title">Back to Shopping</span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                ) : (
                                    <table className="table table-striped table-bordered tbl-shopping-cart">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Photo</th>
                                                <th>Product Name</th>
                                                <th>Price</th>
                                                <th>Quantity</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products.map((product, index) => (
                                                <tr className="cart_item" key={product.id}>
                                                    <td className="product-remove">
                                                        <Link
                                                            title="Remove this item"
                                                            className="remove"
                                                            to="#"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeItemFromCart(product.id);
                                                            }}>
                                                            ×
                                                        </Link>
                                                    </td>
                                                    <td className="product-thumbnail">
                                                        <Link to="#">
                                                            <img alt="product" src={product.product_thumbnail_image} />
                                                        </Link>
                                                    </td>
                                                    <td className="product-name">
                                                        <Link to="#">{product.product_name}</Link>
                                                        {product.size && (
                                                            <ul className="variation">
                                                                <li className="variation-size">Size: <span>{product.size}</span></li>
                                                            </ul>
                                                        )}
                                                    </td>
                                                    <td className="product-price">
                                                        <span className="amount">{product.product_price}</span>
                                                    </td>
                                                    <td className="product-quantity">
                                                        <div className="product-details__quantity">
                                                            <div className="quantity-box">
                                                                <button type="button" className="sub" onClick={() => handleQuantityChange(index, -1)}><i className="fa fa-minus"></i></button>
                                                                <input type="number" value={quantities[index]} readOnly />
                                                                <button type="button" className="add" onClick={() => handleQuantityChange(index, 1)}><i className="fa fa-plus"></i></button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="product-subtotal">
                                                        <span className="amount">{product.product_price * quantities[index]}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                )}
                            </div>
                        </div>
                        {products.length > 0 && (
                            <div className="col-md-12 mt-30">
                                <div className="row">
                                    <div className="col-md-8">
                                        <h4>Cart Totals</h4>
                                        <table className="table table-bordered cart-total">
                                            <tbody>
                                                <tr>
                                                    <td>Cart Subtotal</td>
                                                    <td>₹{calculateCartTotal().toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <td>Shipping and Handling</td>
                                                    <td>₹70.00</td>
                                                </tr>
                                                <tr>
                                                    <td>Order Total</td>
                                                    <td>₹{(calculateCartTotal() + 70).toFixed(2)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <Link className="theme-btn btn-style-one" to="/checkout">
                                            <span className="btn-title">Proceed to Checkout</span>
                                        </Link>
                                    </div>
                                    <div className="col-md-4">
                                        <h4>Coupon Code</h4>
                                        <form className="row g-3 coupon-form">
                                            <div className="col-auto">
                                                <input type="text" name="coupon_code" className="input-text form-control mr-1" id="coupon_code" placeholder="Coupon code" />
                                            </div>
                                            <div className="col-auto">
                                                <button type="submit" className="theme-btn btn-style-one" name="apply_coupon" value="Apply Coupon">
                                                    <span className="btn-title">Apply Coupon</span>
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ShoppingCart;
