import React from 'react';

const VideoSection = () => {
    return (
        <section className="video-section">
        <div className="auto-container">
            <div className="row align-items-center">
                {/* Video */}
                <div className="col-lg-8 col-sm-8">
                    <video className="w-100"
                        id="videobcg"
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                        poster="/images/niyalivingvideo.mp4"
                    >
                        <source
                            type="video/mp4"
                            src="/images/niyalivingvideo.mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>

                {/* Text */}
                <div className="col-lg-4 col-sm-4">
                    <p className="text">
                    We have chosen solid wood and traditional assembly to design and create furniture full of personality.
                    We have chosen solid wood and traditional assembly to design and create furniture full of personality.
                    </p>
                </div>
            </div>
        </div>
        </section>
    );
};

export default VideoSection;
