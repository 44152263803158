import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import api from '../api';

const PaymentResponse = () => {
    const [paymentStatus, setPaymentStatus] = useState('');
    const [message, setMessage] = useState('');
    const location = useLocation();

    useEffect(() => {
        // Extract query parameters from the URL (if necessary)
        const params = new URLSearchParams(location.search);
        const paymentId = params.get('paymentId');  // Example: Extract paymentId

        // Send a request to Laravel to verify the payment
        const verifyPayment = async () => {
            try {
                const response = await api.post('/payment-response', {
                    paymentId: paymentId,  // You might want to send other details from the URL or state
                });

                if (response.data.status === 'success') {
                    setPaymentStatus('success');
                    setMessage(response.data.message);
                } else {
                    setPaymentStatus('error');
                    setMessage(response.data.message);
                }
            } catch (error) {
                setPaymentStatus('error');
                setMessage('An error occurred while processing your payment.');
            }
        };

        verifyPayment();
    }, [location]);

    return (
        <div>
            <h1>Payment Status</h1>
            <p>{message}</p>
            {paymentStatus === 'success' ? (
                <div>Payment was successful!</div>
            ) : (
                <div>There was an issue with your payment.</div>
            )}
        </div>
    );
};

export default PaymentResponse;

