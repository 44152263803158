import React, { useState, useEffect } from "react";
import FooterServices from '../../components/FooterServices';
import { Helmet } from "react-helmet";
import PageTitle from "./modules/PageTitle";
import ProductFilter from "./modules/ProductFilter";
import { Link } from "react-router-dom";
import api from '../../api';
import { toast, ToastContainer } from 'react-toastify';

const Products = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [metaDescription, setMetaDescription] = useState("We provide a comprehensive range of carpentry services tailored to meet your needs.");
    const [metaTitle, setMetaTitle] = useState("Welcome to Niya Living");
    const [metaKeywords, setMetaKeywords] = useState("carpentry, services, carpenter, home improvement");
    const [title, setTitle] = useState("Welcome to Niya Living");
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loginData, setLoginData] = useState(null);

    useEffect(() => {
        const userData = sessionStorage.getItem('user_data');
        if (userData) {
            try {
                const parsedData = JSON.parse(userData);
                setLoginData(parsedData);
            } catch {
                setLoginData(null);
            }
        } else {
            setLoginData(null);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/products/featured');
                setProducts(response.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCart = async (id, variant, user_id, quantity) => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            toast.info("You need to be logged in to add items to the cart.");
            return;
        }

        try {
            const response = await api.post('/carts/add', {
                id, variant, user_id, quantity
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "An error occurred while processing your request.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handleWishlist = async (slug) => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            toast.info("You need to be logged in to add items to the wishlist.");
            return;
        }

        try {
            const response = await api.get(`/wishlists-add-product/${slug}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.data && response.data.result) {
                toast.success(response.data.message);
            } else {
                toast.error("An error occurred while processing your request.");
            }
        } catch (err) {
            toast.error(err.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={metaKeywords} />
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />
            </Helmet>
            <PageTitle />
            <ProductFilter />

            <section className="products-section">
                <div className="auto-container">
                    <div className="row">
                        {products.map((product) => (
                            <div className="product-block col-lg-3 col-md-6 col-sm-6 col-6" key={product.id}>
                                <div className="inner-box">
                                    <div className="image"><Link to={`/products/${product.slug}`}><img
                                        src={product.thumbnail_image} alt={product.name} /></Link></div>
                                    {/* <div className="product-new">{product.discount}</div>
                                    <div className="product-made">{product.discount}</div> */}
                                    <div className="content-box">
                                        <Link to={`/products/${product.slug}`} className="buy-btn">
                                            BUY NOW
                                        </Link>
                                        <Link
                                            to="#"
                                            onClick={() => handleCart(product.id, '', loginData?.id, 1)}
                                            className="cart-btn"
                                        >
                                            ADD TO CART
                                        </Link>
                                    </div>
                                    <div className="content">
                                        <h4><Link to={`/products/${product.slug}`}>{product.name}</Link></h4>
                                        {/* <span className="price">{product.main_price}</span> */}
                                    </div>
                                    {/* <div className="product-made">MADE TO ORDER</div>
                                    <div className="product-new">NEW</div> */}
                                    <div className="icon-box">
                                        {loginData ? (
                                            <Link
                                                to="#"
                                                onClick={() => handleWishlist(product.slug)}
                                                className="ui-btn like-btn"
                                                aria-label="Add to Wishlist"
                                            >
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        ) : (
                                            <Link to="/login" className="ui-btn add-to-cart" aria-label="Login to Add to Cart">
                                                <i className="fa fa-heart"></i>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </section>
            <FooterServices />
        </>
    );
};

export default Products;