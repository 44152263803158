import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";

const StoriesSection = () => {
    const carouselOptions = {
        loop: true,
        margin: 10,
        nav: true,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsive: {
            0: { items: 2 },
            600: { items: 2 },
            1000: { items: 4 },
        },
    };

    return (
        <section className="project-section-five">
            <div className="auto-container">
                <div className="text-center">
                    <h3 className="sec-title">STORIES THAT CONNECT US</h3>
                </div>

                <div className="owl-carousel-wrapper">
                    <OwlCarousel className="projects-carousel-five owl-theme" {...carouselOptions}>
                        <div className="project-block-five">
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image">
                                        <Link to="/stories" className="lightbox-image">
                                            <img src="/images/story-1.jpg" alt="Kitchen Remodel" />
                                        </Link>
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <Link to="/stories">
                                        <h6 className="title">TANVI THAKKER</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="project-block-five">
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image">
                                        <Link to="/stories" className="lightbox-image">
                                            <img src="/images/story-2.jpg" alt="Kitchen Remodel" />
                                        </Link>
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <Link to="/stories">
                                        <h6 className="title">AADHYA SHARMA</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="project-block-five">
                            <div className="inner-box">
                                <div className="image-box">
                                    <figure className="image">
                                        <Link to="/stories" className="lightbox-image">
                                            <img src="/images/story-3.jpg" alt="Office Building" />
                                        </Link>
                                    </figure>
                                </div>
                                <div className="content-box">
                                    <Link to="/stories">
                                        <h6 className="title">TISCA CHOPRA</h6>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
                <div className="text-center pt-4">
                    <Link to={`/stories`} className="read-more">
                        View All
                    </Link>
                </div>
            </div>
        </section>
    );
};

export default StoriesSection;
