import React, { useEffect, useState } from 'react';
import FooterServices from '../../components/FooterServices';
import { Helmet } from "react-helmet";
import BannerSection from "./modules/BannerSection";
import ProductSection from "./modules/ProductSection";
import ShopCategory from "./modules/ShopCategory";
import ClientsSection from "./modules/ClientsSection";
import ProjectSection from "./modules/ProjectSection";
import StoriesSection from "./modules/StoriesSection";
import BlogSection from "./modules/BlogSection";
import VideoSection from "./modules/VideoSection";
import ProductBanner from "./modules/ProductBanner";
import api from '../../api';

export default function Home() {
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [metaData, setMetaData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/business-settings');
                setSettings(response.data.data.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (settings.length > 0) {
            const meta = {};
            settings.forEach(setting => {
                if (setting.type === 'meta_title') {
                    meta.meta_title = setting.value;
                }
                if (setting.type === 'meta_keywords') {
                    meta.meta_keywords = setting.value;
                }
                if (setting.type === 'meta_description') {
                    meta.meta_description = setting.value;
                }
                if (setting.type === 'website_name') {
                    meta.website_name = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    if (error) return <div>Error: {error.message}</div>;

    return (
        <>
            <Helmet>
                <title>{metaData.website_name || "Default Site Title"}</title>
                <meta name="keywords" content={metaData.meta_keywords || ''} />
                <meta name="title" content={metaData.meta_title || ''} />
                <meta name="description" content={metaData.meta_description || ''} />
            </Helmet>
            <BannerSection />
            <ShopCategory />
            <ProductBanner />
            <ProductSection />
            <VideoSection />
            <ClientsSection />
            <ProjectSection />
            <StoriesSection />
            <BlogSection />
            <FooterServices />
        </>
    );
}
