import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { toast } from "react-toastify";

const MobileMenu = ({ isVisible, toggleMenu, menuItems }) => {
    const [metaData, setMetaData] = useState({});
    const [settings, setSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get("/business-settings");
                setSettings(response.data.data.data);
            } catch (err) {
                setError(err);
                toast.error("Failed to load business settings.");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (Array.isArray(settings)) {
            const meta = {};
            settings.forEach((setting) => {
                if (setting.type === "header_logo_path") {
                    meta.header_logo_path = setting.value;
                }
                if (setting.type === "address") {
                    meta.address = setting.value;
                }
                if (setting.type === "website_name") {
                    meta.website_name = setting.value;
                }
                if (setting.type === "phone") {
                    meta.phone = setting.value;
                }
                if (setting.type === "email") {
                    meta.email = setting.value;
                }
                if (setting.type === "facebook_link") {
                    meta.facebook_link = setting.value;
                }
                if (setting.type === "twitter_link") {
                    meta.twitter_link = setting.value;
                }
                if (setting.type === "instagram_link") {
                    meta.instagram_link = setting.value;
                }
                if (setting.type === "linkedin_link") {
                    meta.linkedin_link = setting.value;
                }
            });
            setMetaData(meta);
        }
    }, [settings]);

    if (!isVisible) return null;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <div className="mobile-menu">
            <div className="menu-backdrop" onClick={toggleMenu}></div>
            <nav className="menu-box">
                <div className="upper-box">
                    <div className="nav-logo">
                        <Link to="#">
                            <img
                                src={metaData.header_logo_path || "/path/to/default/logo.png"}
                                alt={metaData.website_name || "Website"}
                            />
                        </Link>
                    </div>
                    <div className="close-btn" onClick={toggleMenu}>
                        <i className="icon fa fa-times"></i>
                    </div>
                </div>
                <ul className="navigation clearfix">
                    {menuItems.map((item, index) => (
                        <li key={index} className={item.subMenu ? "dropdown" : ""}>
                            <Link to={item.link} onClick={toggleMenu}>{item.title}</Link>
                            {item.subMenu && (
                                <ul className="submenu">
                                    {item.subMenu.map((subItem, subIndex) => (
                                        <li key={subIndex}>
                                            <Link to={subItem.link} onClick={toggleMenu}>{subItem.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}

                </ul>
                <ul className="contact-list-one">
                    <li>
                        <i className="icon lnr-icon-phone-handset"></i>
                        <span className="title">Call Now</span>
                        <div className="text">
                            <Link to={`tel:${metaData.phone || "+1234567890"}`}>
                                {metaData.phone || "Unavailable"}
                            </Link>
                        </div>
                    </li>
                    <li>
                        <i className="icon lnr-icon-envelope1"></i>
                        <span className="title">Send Email</span>
                        <div className="text">
                            <Link to={`mailto:${metaData.email || "info@website.com"}`}>
                                {metaData.email || "Unavailable"}
                            </Link>
                        </div>
                    </li>
                    <li>
                        <i className="icon lnr-icon-map-marker"></i>
                        <span className="title">Address</span>
                        <div className="text">{metaData.address || "Unavailable"}</div>
                    </li>
                </ul>
                <ul className="social-links">
                    <li>
                        <Link to={metaData.twitter_link}>
                            <i className="fab fa-twitter"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to={metaData.instagram_link}>
                            <i className="fab fa-instagram"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to={metaData.facebook_link}>
                            <i className="fab fa-facebook"></i>
                        </Link>
                    </li>
                    <li>
                        <Link to={metaData.linkedin_link}>
                            <i className="fab fa-linkedin-in"></i>
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

export default MobileMenu;
