import React from "react";
import Sidenav from "./modules/Sidenav";

const PurchaseHistory = () => {
  return (
    <section className="dashboard">
      <div className="auto-container">
        <div className="d-flex align-items-start">
          <Sidenav />

          <div className="aiz-user-panel">
            <div className="card shadow-none rounded-0 border">
              <div className="card-header border-bottom-0 bg-transparent">
                <h5 className="mb-0 fs-20 fw-700 text-dark">Purchase History</h5>
              </div>
              <div className="card-body">
                <table className="table aiz-table mb-0 footable footable-1 breakpoint-lg">
                  <thead className="text-gray fs-12">
                    <tr className="footable-header">
                      <th className="pl-0">Code</th>
                      <th data-breakpoints="md">Date</th>
                      <th>Amount</th>
                      <th data-breakpoints="md">Delivery Status</th>
                      <th data-breakpoints="md">Payment Status</th>
                      <th className="text-right pr-0">Options</th>
                    </tr>
                  </thead>
                  <tbody className="fs-14">
                    <tr className="footable-empty">
                      <td colspan="6">Nothing found</td>
                    </tr>
                  </tbody>
                </table>
                <div className="aiz-pagination mt-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PurchaseHistory;
